var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { AvatarGroup } from '@mui/material';
// ----------------------------------------------------------------------
var SIZES = {
    tiny: 24,
    small: 32,
    medium: 40,
    large: 56,
};
var CustomAvatarGroup = forwardRef(function (_a, ref) {
    var _b = _a.size, size = _b === void 0 ? 'small' : _b, compact = _a.compact, max = _a.max, children = _a.children, sx = _a.sx, other = __rest(_a, ["size", "compact", "max", "children", "sx"]);
    var isTiny = size === 'tiny';
    var isSmall = size === 'small';
    var isMedium = size === 'medium';
    var isLarge = size === 'large';
    var compactStyle = {
        width: 40,
        height: 40,
        position: 'relative',
        '& .MuiAvatarGroup-avatar': {
            m: 0,
            width: 28,
            height: 28,
            position: 'absolute',
            '&:first-of-type': {
                left: 0,
                bottom: 0,
                zIndex: 9,
            },
            '&:last-of-type': {
                top: 0,
                right: 0,
            },
        },
    };
    return (_jsx(AvatarGroup, __assign({ ref: ref, max: compact ? 3 : max, spacing: ((isTiny || isSmall) && 'medium') || 'small', sx: __assign(__assign({ '& .MuiAvatar-root': __assign(__assign(__assign(__assign({}, (isLarge && {
                width: SIZES.large,
                height: SIZES.large,
                '&:first-of-type': { fontSize: 16 },
            })), (isMedium && {
                width: SIZES.medium,
                height: SIZES.medium,
            })), (isSmall && {
                width: SIZES.small,
                height: SIZES.small,
            })), (isTiny && {
                width: SIZES.tiny,
                height: SIZES.tiny,
            })) }, (compact && __assign({}, compactStyle))), sx) }, other, { children: children })));
});
export default CustomAvatarGroup;
